@charset "utf-8";

@font-face {
    font-family: 'Noto Sans Japanese';
    font-style: normal;
    font-weight: 100;
    src: url('./font/NotoSansCJKjp-Thin.eot'); /* IE9 Compat Modes */
    src: url('./font/NotoSansCJKjp-Thin.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('./font/NotoSansCJKjp-Thin.woff') format('woff'), /* Modern Browsers */
    url('./font/NotoSansCJKjp-Thin.ttf')  format('truetype'); /* Safari, Android, iOS */
}

@font-face {
    font-family: 'Noto Sans Japanese';
    font-style: normal;
    font-weight: 200;
    src: url('./font/NotoSansCJKjp-Light.eot');
    src: url('./font/NotoSansCJKjp-Light.eot?#iefix') format('embedded-opentype'),
    url('./font/NotoSansCJKjp-Light.woff') format('woff'),
    url('./font/NotoSansCJKjp-Light.ttf')  format('truetype');
}
@font-face {
    font-family: 'Noto Sans Japanese';
    font-style: normal;
    font-weight: 300;
    src: url('./font/NotoSansCJKjp-DemiLight.eot');
    src: url('./font/NotoSansCJKjp-DemiLight.eot?#iefix') format('embedded-opentype'),
    url('./font/NotoSansCJKjp-DemiLight.woff') format('woff'),
    url('./font/NotoSansCJKjp-DemiLight.ttf')  format('truetype');
}
@font-face {
    font-family: 'Noto Sans Japanese';
    font-style: normal;
    font-weight: 400;
    src: url('./font/NotoSansCJKjp-Regular.eot');
    src: url('./font/NotoSansCJKjp-Regular.eot?#iefix') format('embedded-opentype'),
    url('./font/NotoSansCJKjp-Regular.woff') format('woff'),
    url('./font/NotoSansCJKjp-Regular.ttf')  format('truetype');
}
@font-face {
    font-family: 'Noto Sans Japanese';
    font-style: normal;
    font-weight: 500;
    src: url('./font/NotoSansCJKjp-Medium.eot');
    src: url('./font/NotoSansCJKjp-Medium.eot?#iefix') format('embedded-opentype'),
    url('./font/NotoSansCJKjp-Medium.woff') format('woff'),
    url('./font/NotoSansCJKjp-Medium.ttf')  format('truetype');
}
@font-face {
    font-family: 'Noto Sans Japanese';
    font-style: normal;
    font-weight: 700;
    src: url('./font/NotoSansCJKjp-Bold.eot');
    src: url('./font/NotoSansCJKjp-Bold.eot?#iefix') format('embedded-opentype'),
    url('./font/NotoSansCJKjp-Bold.woff') format('woff'),
    url('./font/NotoSansCJKjp-Bold.ttf')  format('truetype');
}
@font-face {
    font-family: 'Noto Sans Japanese';
    font-style: normal;
    font-weight: 900;
    src: url('./font/NotoSansCJKjp-Black.eot');
    src: url('./font/NotoSansCJKjp-Black.eot?#iefix') format('embedded-opentype'),
    url('./font/NotoSansCJKjp-Black.woff') format('woff'),
    url('./font/NotoSansCJKjp-Black.ttf')  format('truetype');
}
