@charset "utf-8";

.content {
	padding: 60px 0 130px;
	margin: 0 auto;
	width: 800px;
	@include screen-mobile {
		padding: 100px 0 60px;
		width: auto;
	}

	.hero {
		padding-bottom: 80px;
		margin: auto;
		width: 310px;
		text-align: center;

		&-title {
			font-size: 16px;
			font-weight: normal;
		}

		&-caption {
			margin-bottom: 20px;
			font-size: 56px;
			font-weight: bold;
			@include border-bottom;
			@include screen-mobile {
				padding-bottom: 10px;
				margin-bottom: 10px;
				font-size: 28px;
			}
		}
	}

	//recruit
	.recruit-jobs {
		padding: 40px;
		margin-bottom: 20px;
		background: #271a27;
		@include screen-mobile {
			padding: 4%;
		}

		&__title{
			position: relative;
			padding: 5px 0 10px;
			margin-bottom: 32px;
			width: 310px;
			font-size: 30px;
			@include border-bottom;
			@include screen-mobile {
				padding: 10px 0;
				margin-bottom: 10px;
				width: auto;
				font-size: 15px;
			}
			&::before {
				content: "";
				position: absolute;
				top: 0;
				display: inline-block;
				height: 3px;
				width: 18px;
				background: #fff;
			}
		}
	}
	.recruit-inquiry {
		margin-top: 40px;
		text-align: center;
	}

	.recruit-requirements{
		padding: 0 30px 15px;
		margin-bottom: 15px;
		@include border-bottom;
		@include screen-mobile {
			padding: 0 3% 15px;
			margin: 0 auto 15px;
			width: 90%;
		}

		&::after {
			@include clear;
		}

		&-title {
			float: left;
			width: 160px;
			@include screen-mobile {
				float: none;
				padding-bottom: 5px;
				width: auto;
				font-size: 15px;
				font-weight: bold;
			}
		}

		&-details {
			float: right;
			width: 540px;
			@include screen-mobile {
				float: none;
				width: auto;
			}
		}
	}

	//応募フォーム
	.entry-form {
		padding-bottom: 19px;
		@include screen-mobile {
			padding: 0 5% 30px;
		}

		&-title {
			float: left;
			width: 264px;
			@include screen-mobile {
				padding-bottom: 8px;
				float: none;
				width: auto;
				font-size: 14px;
				font-weight: bold;
			}

			&__rice {
				font-size: 12px;
			}

      .text.is_emphasis {
        color: #dc0202;
      }
		}

		&-details {
			float: right;
			width: 510px;
			@include screen-mobile {
				float: none;
				width: auto;
			}

			&__rice {
				font-size: 12px;
				@include screen-mobile {
					padding-top: 5px;
				}
			}

			&__text {
				padding: 8px;
				width: 400px;
				background: #271926;
				color: #fff;
				border: 0;
				border-radius: 6px;
				-webkit-appearance:none;
				@include screen-mobile {
					width: 100%;
				}

				&.is_half {
					width: 200px;
				}

        &.is_birthday {
					width: 100px;
          @include screen-mobile {
            margin-bottom: 5px;
  					width: 200px;
  				}
				}
			}

			&__select {
				padding: 0 8px;
				width: 60px;
				height: 30px;
				background: #271926;
				color: #fff;
				border: 0;
				border-radius: 6px;
				-webkit-appearance:none;
			}

			&__textarea {
				padding: 8px;
				width: 500px;
				height: 150px;
				background: #271926;
				color: #fff;
				border: 0;
				border-radius: 6px;
				-webkit-appearance:none;
				@include screen-mobile {
					width: 100%;
				}
			}
		}

		&::after {
			@include clear;
		}
	}

	&-contact-summary {
		padding-bottom: 30px;
		@include screen-mobile {
			padding: 0 5% 30px;
		}
	}

  .text.is_caution {
    text-align: center;
  }
}
