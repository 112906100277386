@charset "utf-8";

.header-wrap {
	position: relative;
	height: 102px;
	background: linear-gradient(rgba(3, 2, 3, 0.4), rgba(3, 2, 3, 0.3),rgba(3, 2, 3, 0.4));
	z-index: 9999;
	@include screen-mobile {
		height: 50px;
		background: #000;
	}

	.header-nav-list {
		&::after {
			@include clear;
		}

		&.is_child {
			@include screen-pc {
				display: none;
			}
			@include screen-mobile {
				display: block;
			}
		}

		&-item {
			float: left;
			width: 130px;
			text-align: center;
			@include screen-mobile {
				float: none;
				width: auto;
				background: #000;
				border-bottom: #1d1c1d 2px solid;
			}

			&-link {
				display: block;
				padding: 32px 0;
				font-size: 16px;
				font-weight: bold;
				line-height: 1.2;

				&__caption {
					font-size: 10px;
					font-weight: normal;
				}
			}
		}
	}

	.header-nav-child {
		@include screen-mobile {
			display: none;
		}
	}

	.header-inner {
		position: relative;
		padding: 0 40px;
		@include screen-mobile {
			padding: 0;
		}

		.header-logo {
			position: absolute;
			top: 36px;
			.header-logo__image {
				width: 72px;
				height: auto;
			}
		}

		.breadcrumbs {
			position: absolute;
			top: 40px;
			left: 130px;
		}
	}

	@include screen-mobile {
		#header-toggle {
			display: block;
			width: 40px;
			height: 27px;
			position: relative;
			top: 11px;
			left: 4%;
			z-index: 100;
			cursor: pointer;
		}
		#header-toggle {
			div { position: relative;}

			span {
				display: block;
				height: 3px;
				background: #fff;
				position:absolute;
				width: 100%;
				left: 0;
				-webkit-transition: 0.3s ease-in-out;
				-moz-transition: 0.3s ease-in-out;
				transition: 0.3s ease-in-out;

				&:nth-child(1) { top: 0;}
				&:nth-child(2) { top: 12px;}
				&:nth-child(3) { top: 24px;}
			}

			&.is_open {
				span {
					&:nth-child(1) {
					  top: 12px;
					  -webkit-transform: rotate(135deg);
					  -moz-transform: rotate(135deg);
					  transform: rotate(135deg);
					}

					&:nth-child(2) {
					  width: 0;
					  left: 50%;
					}

					&:nth-child(3) {
					  top: 12px;
					  -webkit-transform: rotate(-135deg);
					  -moz-transform: rotate(-135deg);
					  transform: rotate(-135deg);
					}
				}
			}
		}

		.header-nav-list-wrap {
			display: none;
			position: absolute;
			top: -20px;
			left: 0;
			width: 100%;
			background: #006598;
		}

	}
}
