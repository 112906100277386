@charset "utf-8";
/*
Theme Name: swiftHP 子ページ用レイアウト
*/

.page-child .content,
.nav-contact .content {
	.hero {
		width: auto;
		@include screen-mobile {
			width: 90%;
		}

		&-title {
			margin-bottom: 20px;
			font-size: 56px;
			font-weight: bold;
			border-bottom: #3d313d 1px solid;
			@include screen-mobile {
				padding-bottom: 10px;
				font-size: 28px;
			}
		}
	}
}
