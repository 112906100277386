@charset "utf-8";

.front-hero-wrap {
	position: relative;
}

.front-hero {
	position: relative;
	margin: auto;
	background: url('./images/bg_splash01.png') top center no-repeat;
	width: 100%;
	max-width: 1920px;
	height: 1103px;
	@include screen-desktop {
		height: 900px;
	}
	@include screen-laptop {
		height: 800px;
	}
	@include screen-mobile {
		background: url('./images/flont-logo.png') top center no-repeat;
		background-size: 320px;
		max-width: none;
		height: 320px;
	};
	z-index: 5;

	&-inner {
    position: absolute;
    bottom: 300px;
    left: 0;
    right: 0;
    width: auto;
		max-width: 840px;
    margin: auto;
    text-align: center;
		@include screen-desktop {
			bottom: 80px;
		}
		@include screen-laptop {
			bottom: 100px;
		}
		@include screen-mobile {
			padding: 0 5%;
			bottom: 0;
		}

		&__title {
			margin-bottom: 20px;
			font-size: 44px;
			@include border-bottom;
			@include screen-mobile {
				padding-bottom: 8px;
				margin-bottom: 10px;
				font-size: 18px;
			}
		}

		&__caption {
			font-size: 16px;
			@include screen-mobile {
				font-size: 11px;
			}
		}
	}
}

.front-hero-video {
	position: absolute;
	top: -150px;
	right: 0;
	left: 0;
	margin: auto;
	width: 100%;
	max-width: 1280px;
	z-index: 2;
	@include screen-desktop {
		top: -100px;
	}
	@include screen-laptop {
		top: -100px;
	}
	@include screen-mobile {
		display: none;
	}
}

.message-wrap {
	position: relative;
	z-index: 0;
}

.message {
	width: 100%;
	height: 0;
	padding-bottom: 64%;
	background: linear-gradient(90deg, #bebebe 43%, rgba(255,255,255,0) 50%), url('./images/img_message.jpg') center center / cover no-repeat;
	@include screen-desktop {
		background: linear-gradient(90deg, #bebebe 50%, rgba(255,255,255,0) 60%), url('./images/img_message.jpg') center center / cover no-repeat;
		padding-bottom: 80%;
	}
	@include screen-laptop {
		background: linear-gradient(90deg, #bebebe 50%, rgba(255,255,255,0) 60%), url('./images/img_message.jpg') center center / cover no-repeat;
		padding-bottom: 80%;
	}
	@include screen-mobile {
		background: url('./images/sp_img_message.jpg') center center / cover no-repeat;
		padding-bottom: 130%;
	}
	@include screen-iphone5 {
		padding-bottom: 150%;
	}

	&-inner {
		position: absolute;
    top: 24%;
    left: 4%;
		width: 580px;
		color: #251313;
		@include screen-laptop {
			top: 12%;
			left: 3%;
			width: 400px;
		}
		@include screen-mobile {
			position: absolute;
			top: 19%;
	    left: 4%;
			padding: 4%;
			width: 50%;
			color: #fff;
			background: rgba(26, 12, 25, 0.9);
		}
		@include screen-iphone5 {
			width: 55%;
		}

		&-title{
			position: relative;
			padding: 5px 0 10px;
			margin-bottom: 32px;
			font-size: 56px;
			@include screen-mobile {
				margin-bottom: 10px;
				font-size: 20px;
			}

			&__caption {
				display: table;
				margin-top: -10px;
				font-size: 16px;
				@include screen-mobile {
					margin-top: 0;
					font-size: 10px;
				}
			}
			&::before {
				content: "";
				position: absolute;
				top: 0;
				display: inline-block;
				height: 3px;
				width: 18px;
				background: #251313;
				@include screen-mobile {
					background: #fff;
				}
			}
			&::after {
				content: "";
				position: absolute;
				bottom: 0;
				left: 0;
				display: inline-block;
				height: 1px;
				width: 100%;
				max-width: 380px;
				background: #aeadad;
				@include screen-mobile {
					max-width: 400px;
				}
			}
		}

		&__text {
			font-size: 16px;
			@include screen-laptop {
				font-size: 13px;
			}
			@include screen-mobile {
				font-size: 10px;
			}

			&.is_name {
				display: block;
				font-size: 12px;
				line-height: 1.5;
				@include screen-mobile {
					font-size: 10px;
				}
			}
		}
		&-name {
			position: absolute;
			bottom: -6%;
			right: 8%;
			@include screen-laptop {
				right: -5%;
			}
			@include screen-mobile {
				bottom: 2%;
				right: -15%;
			}
			@include screen-halfway {
				bottom: 2%;
				right: -60%;
			}
			@include screen-tablet {
				bottom: 3%;
				right: -8%;
			}
			@include screen-iphone5 {
				bottom: 6%;
				right: -50%;
			}

			&.is_pc {
				@include screen-mobile {
					display: none;
				}
			}
			&.is_sp {
				@include screen-pc {
					display: none;
				}
			}

			&__image {
				@include screen-laptop {
					width: 80%;
				}
				@include screen-mobile {
					width: 64%;
				}
				@include screen-tablet {
					width: 80%;
				}
				@include screen-iphone5 {
					width: 50%;
				}
			}
		}
	}

	&::before,
	&::after {
		content: '';
		position: absolute;
		left: 0;
		width: 100%;
		height: 0;
		padding-bottom: 33%;
		@include body-background;
		-webkit-transform: skewY(-3deg);
		transform: skewY(-3deg);
		z-index: 1;
	}

	&::before {
		top: -42%;
		@include screen-desktop {
			top: -37%;
		}
		@include screen-laptop {
			top: -37%;
		}
		@include screen-mobile {
			top: -140px;
			padding-bottom: 200px;
		}
	}

	&::after {
		bottom: -38%;
		@include screen-mobile {
			bottom: -95px;
		}
	}
}

.front-content {
	position: relative;
	background: url('./images/bg_splash02.png') top center repeat-y;
	z-index: 1;

	&__title {
		position: relative;
		margin-bottom: 20px;
		font-size: 56px;
		text-align: center;
		@include screen-mobile {
			padding-bottom: 10px;
			margin-bottom: 10px;
			font-size: 28px;
		}

		&::after {
			content: "";
			position: absolute;
			bottom: 0;
			left: 0;
			right: 0;
			display: inline-block;
			margin: auto;
			height: 1px;
			width: 310px;
			background: #3d313d;
			@include screen-mobile {
				width: 80%;
			}
		}
	}

	&__caption {
		padding-bottom: 60px;
		font-size: 16px;
		text-align: center;
		@include screen-mobile {
			padding: 0 7% 30px;
			font-size: 12px;
		}
	}

	&-products {
		padding-bottom: 130px;
		margin: auto;
		width: auto;
		max-width: 800px;

		&-solution {
			padding: 40px;
			margin-bottom: 20px;
			background: #271a27;
			@include screen-mobile {
				padding: 4%;
			}

			&__title{
				position: relative;
				padding: 5px 0 10px;
				margin-bottom: 32px;
				font-size: 30px;
				@include screen-mobile {
					padding: 10px 0;
					margin-bottom: 10px;
					font-size: 15px;
				}
				&::before {
					content: "";
					position: absolute;
					top: 0;
					display: inline-block;
					height: 3px;
					width: 18px;
					background: #fff;
				}
				&::after {
					content: "";
					position: absolute;
					bottom: 0;
					left: 0;
					display: inline-block;
					height: 1px;
					width: 310px;
					background: #3d313d;
					@include screen-mobile {
						width: 100%;
					}
				}
			}
		}
		&__caution {
			color: #dc0202;
			text-align: center;
			@include screen-mobile {
				padding: 0 3% 10px;
			};
		}
		&__contact {
			text-align: center;
		}
	}

	&-recruit {
		padding-bottom: 120px;

		&-image {
			width: 100%;
			height: auto;

			&-wrap {
				padding-bottom: 30px;
				margin: auto;
			}
		}

		&-list {
			display: flex;
			flex-wrap: wrap;
			justify-content: start;
			margin: auto;
			max-width: 1140px;
			@include screen-mobile {
					margin: auto;
			}

			&-item {
				padding: 30px;
        margin-right: 24px;
				margin-bottom: 30px;
				width: 364px;
				background: rgba(39, 26, 39, 0.9);
				@include screen-mobile {
					padding: 4%;
          margin-right: auto;
          margin-left: auto;
					width: 88%;
				}
        &:nth-child(3n) {
          margin-right: 0;
          @include screen-mobile {
            margin-right: auto;
            margin-left: auto;
  				}
        }

				&__title{
					position: relative;
					padding: 5px 0 10px;
					margin-bottom: 24px;
					font-size: 30px;
					@include border-bottom;
					@include screen-mobile {
						padding: 10px 0;
						margin-bottom: 10px;
						font-size: 15px;
					}
					&::before {
						content: "";
						position: absolute;
						top: 0;
						display: inline-block;
						height: 3px;
						width: 18px;
						background: #fff;
					}
				}
			}
		}
	}

	&-map {
		padding-bottom: 100px;
		margin: auto;
		max-width: 1152px;
	}

	.front-content-company {
		padding-bottom: 100px;
		@include screen-mobile {
			padding: 0 5% 100px;
			max-width: none;
		}
	}

	.company-profile {
		padding: 0 20px 15px;
		margin: 0 auto 15px;
		max-width: 580px;
		@include border-bottom;
		@include screen-mobile {
			padding: 0 0% 15px;
			max-width: none;
		}

		&::after {
			@include clear;
		}

		&__title{
			float: left;
			width: 90px;
			@include screen-mobile {
				width: 24%;
			}
		}

		&__details {
			float: right;
			width: 410px;
			@include screen-mobile {
				width: 72%;
			}
		}
	}
}

#map {
	width: 100%;
	height: 600px;
}
