$hd-min: 1200px;
$desktop-max: 1199px;
$desktop-min: 992px;
$laptop-max: 991px;
$laptop-min: 768px;
$tablet-max: 767px;
$tablet-min: 576px;
$mobile-max: 575px;
$mobile-halfway: 480px;
$mobile-min: 380px;
$iphone5-max: 379px;

@mixin screen-hd {
  @media screen and (min-width: $hd-min) {
    @content;
  }
}
@mixin screen-pc {
  @media screen and (min-width: $laptop-min) {
    @content;
  }
}
@mixin screen-desktop {
  @media screen and (min-width: $desktop-min) and (max-width: $desktop-max) {
    @content;
  }
}
@mixin screen-laptop {
  @media screen and (min-width: $laptop-min) and (max-width: $laptop-max) {
    @content;
  }
}
@mixin screen-tablet {
  @media screen and (min-width: $tablet-min) and (max-width: $tablet-max) {
    @content;
  }
}
@mixin screen-halfway {
  @media screen and (min-width: $mobile-min) and (max-width: $mobile-halfway) {
    @content;
  }
}
@mixin screen-mobile {
  @media screen and (max-width: $tablet-max) {
    @content;
  }
}
@mixin screen-iphone5 {
  @media screen and (max-width: $iphone5-max) {
    @content;
  }
}

//基本のボーダー
@mixin border-bottom($color:#3d313d) {
  border-bottom: 1px solid $color;
}

@mixin body-background {
  background: #1a0c19;
}

//clearfix
@mixin clear {
  content: "";
  clear: both;
  display: block;
}
