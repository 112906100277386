@charset "utf-8";

body {
	background: #1a0c19;
	//background: url('./images/background.jpg') repeat;
	font-family: 'Noto Sans Japanese', sans-serif;
	font-size: 14px;
	color: #fff;
	line-height: 1.8;
	letter-spacing: 2px;
	@include screen-mobile {
		font-size: 12px;
		line-height: 1.3;
		letter-spacing: 1px;
	}
}

a {
	color: #fff;
	text-decoration: none;
}

.btn-wrap {
	margin: 40px auto 0;

	&.is_large {
		width: 390px;
		@include screen-mobile {
			width: 290px;
		}
	}

	&.is_small {
		width: 240px;
		@include screen-mobile {
			width: 200px;
		}
	}

	.btn-outline {
		display: inline-block;
		position: relative;
		width: 100%;
		background: transparent;
		border: 1px solid #fff;
		border-radius: 26px;
		text-align: center;
		letter-spacing: 1px;
		line-height: 50px;
		overflow: hidden;
		z-index: 2;
		@include screen-mobile {
			letter-spacing: 0px;
		}

		&::before,&::after{
			content: '';
		  display: block;
		  position: absolute;
		  top: -100%;
		  width: 100%;
		  height: 100%;
		  box-sizing: border-box;
		  transition: all .1s;
		  z-index: -1;
		}

    &:hover {
      border: 1px solid transparent;

			&::after {
				top: 0;
				background: linear-gradient(90deg, #f6154e, #ff6e0d);
			}
		}

    &.is_glay {
      background: #3d363c;
  		color: #1a0c19;
      border: none;

      &::after {
				background: none;
			}
    }
	}

	.btn-color {
		display: inline-block;
		position: relative;
		width: 100%;
		background: linear-gradient(90deg, #f6154e, #ff6e0d);
		color: #fff;
		border: 0;
		border-radius: 40px;
		font-size: 20px;
		text-align: center;
		letter-spacing: 1px;
		line-height: 80px;
		overflow: hidden;
		z-index: 2;

		&:hover {
			font-weight: bold;
		}

    &.is_glay {
      background: #3d363c;
  		color: #1a0c19;
      border: none;

      &::after {
				background: none;
			}
    }
	}

	.btn-back {
		display: inline-block;
		position: relative;
		width: 100%;
		background: transparent;
		color: #fff;
		border: 1px solid #fff;
		border-radius: 26px;
		text-align: center;
		letter-spacing: 1px;
		line-height: 50px;
		overflow: hidden;
		z-index: 2;

		&:hover {
			background: linear-gradient(90deg, #f6154e, #ff6e0d);
			border: 1px solid transparent;
			font-weight: bold;
		}
	}

	.btn-gray {
		display: inline-block;
		position: relative;
		margin-bottom: 30px;
		width: 100%;
		background: #3d363c;
		color: #1a0c19;
		border: 0;
		border-radius: 40px;
		font-size: 30px;
		text-align: center;
		letter-spacing: 1px;
		line-height: 80px;
		overflow: hidden;
		z-index: 2;
	}

}
