@charset "utf-8";

.footer-wrap {
	background: #030203;

	.footer-inner {
		padding: 70px 0;
		margin: auto;
		width: 300px;
		@include screen-mobile {
			padding: 70px 5% 20px;
			width: auto;
		}

		.footer-logo {
			text-align: center;
			.footer-logo__image {
				width: 138px;
				height: auto;
			}
		}

		.footer-list {
			padding: 42px 30px 0;
			text-align: center;
			@include screen-mobile {
				padding: 42px 0 0;
				margin: auto;
			}
			.footer-list-item {
				float: none;
				@include screen-mobile {
					float: none;
					padding-bottom: 20px;
				}
				&:last-child {
					float: none;
					@include screen-mobile {
						float: none;
						padding-bottom: 0;
					}
				}
			}
			&::after {
				@include clear;
			}
		}

		.footer-copy {
			padding-top: 20px;
			margin-top: 20px;
			font-size: 10px;
			text-align: center;
			border-top: #1d1c1d 2px solid;
		}

	}
}
